/* Home.css */

.home__container {
    height: 100vh;
    background-color: #393E46;
}

.gridBox {
    cursor: pointer;
}

.gridBox:hover {
    transform: scale(1.02);
}

.header-top {
    font-size: 50px;
    text-align: center;
}

.homepage {
    background-color: rgb(246, 246, 246);
}

.featureGrid {
    padding-left: 50px;
    padding-right: 50px;
}

.upscinfact__container {
    background-color: rgb(236, 236, 236);
}

.header__container {
    background-color: rgb(231, 231, 231);
    font-weight: 600;
}

.studencity__container {
    background-color: rgb(241, 241, 241);
}

.first__container {
    background-color: #222831;
    padding-bottom: 20px;
}

.first__container .first__heading {
    color: white;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.first__container .first__subheading {
    color: white;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.second__container {
    background-color: #393E46;
    padding-bottom: 20px;
}

.second__container .second__heading {
    color: white;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.third__container {
    background-color: #00ADB5;
    padding-bottom: 20px;
}

.third__container .third__heading {
    color: white;
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.fourth__container {
    background-color: #EEEEEE;
    padding-bottom: 20px;
}

.fourth__container .fourth__heading {
    font-size: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 1300px) {
    .featureGrid {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 900px) {
    .featureGrid {
        padding-left: 10px;
        padding-right: 10px;
    }
}


/* Other */

.others__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
}