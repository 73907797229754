.reloadPage {
    
}

.reloadPage:hover {
    scale: 1.03;
    color: black;
    transform: rotate(-40deg);
    transition-duration: 2s;
}
