.pdf__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 300px;
    row-gap: 20px;
} 

.pdf__container .each__pdf__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
}

.pdf__container .each__pdf__container .each__button {
    font-size: 20px;
    width: 400px;
}