body {
    height: 100%;
    background-color: rgb(246, 246, 246);
}

.gridBox {
    cursor: pointer;
}

.gridBox:hover {
    transform: scale(1.02);
}

.header-top {
    font-size: 50px;
    text-align: center;
}

.homepage {
    background-color: rgb(246, 246, 246);
}

.featureGrid {
    padding-left: 150px;
    padding-right: 150px;
}

.header__div__stats {
    background: linear-gradient(90deg, #272728 0%, #1e1d1e 100%);
}

.header__div__stats__2 {
    background: linear-gradient(90deg, #2d2d2d 0%, #272627 100%);
}

@media screen and (max-width: 1300px) {
    .featureGrid {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 900px) {
    .featureGrid {
        padding-left: 10px;
        padding-right: 10px;
    }
}
